export const NO_IMAGE_URL = '/media/images/no-image-available-icon.jpeg'

export const APP = {
  REQUIRED_FIELD: 'Поле обязательно для заполнения',
  VALID_EMAIL: 'Необходимо ввести валидный E-mail',
  ERROR_PHONE_NUMBER: 'Неправильный номер телефона',
  PASSWORD_MIN_LIMIT: 'Пароль должен содержать не менее 4 символов',
  PUBLISH: 'Опубликовать',
  REMOVE: 'Удалить',
  CHANGE: 'Изменить',
  EXIT: 'Выйти из аккаунта',
  MY_PROFILE: 'Мой профиль',
  ENTER_TO_SYSTEM: 'Войти в систему',
  ADD_USER: 'Добавить пользователя',
  ACTIVATED_USER: 'Активный',
  NOT_ACTIVATED_USER: 'Неактивный',
  FIRST_NAME: 'Имя',
  LAST_NAME: 'Фамилия',
  USERNAME: 'Логин',
  EMAIL: 'Электронная почта',
  PHONE: 'Телефон номер',
  PASSWORD: 'Пароль',
  STATUS: 'Статус',
  CANCEL: 'Отменить',
  SAVE: 'Сохранить',
  PHOTO: 'Фотография',
  REMOVE_PHOTO: 'Удалить фотографию',
  ENTER_FIRST_NAME: 'Введите имя',
  ENTER_LAST_NAME: 'Введите фамилию',
  ENTER_USERNAME: 'Введите логин',
  ENTER_EMAIL: 'Введите Электронную почту',
  ENTER_PHONE: 'Введите телефон номер',
  ENTER_PASSWORD: 'Введите пароль',
  ADMIN: 'Админ',
  ADD_FILE: 'Добавить файл',
  DATA_SUCCESS_SAVED: 'Данные успешно сохранены!',
  DATA_SUCCESS_CHANGED: 'Данные успешно изменены!',
  DATA_SUCCESS_REMOVED: 'Данный успешно удален!',
  DATA_FAILURE_SAVED: 'Данные не сохранены!',
  CHANGE_PASSWORD: 'Изменить пароль',
  OLD_PASSWORD: 'Текущий пароль',
  NEW_PASSWORD: 'Новый пароль',
  CONFIRM_PASSWORD: 'Подтвердите новый пароль',
  ENTER_OLD_PASSWORD: 'Введите текущий пароль',
  ENTER_NEW_PASSWORD: 'Введите новый пароль',
  REPEAT_PASSWORD: 'Повторите пароль',
  PASSWORD_MUST_MATCH: 'Пароль не совпадает',
  PASSWORD_CHANGED: 'Пароль успешно изменен',
  CHANGE_BANNER: 'Изменить баннер',
  TITLE: 'Название',
  LINK: 'Ссылка',
  ENTER_TITLE: 'Введите названию',
  ENTER_LINK: 'Введите ссылку',
  ERROR_LINK: 'URL-адрес должен быть действительным',
  TEXT: 'Текст',
  ADD_HISTORY: 'Добавить историю',
  ADD_PLAN: 'Добавить план',
  DATE_TIME: 'Дата время',
  ENTER_TEXT: 'Введите текст',
  FOR_MONTH: 'На месяц',
  FOR_YEAR: 'На год',
  MONTH: 'Месяц',
  YEAR: 'Год',
  PLAN: 'План',
  ADD_POPUP: 'Добавить поп-ап',
  VIDEO: 'Видео',
  VIDEO_URL: 'Видео URL',
  ADD_NEWS: 'Добавить новости',
  EDIT_NEWS: 'Изменить новости',
  REMOVE_CONFIRMATION: 'Вы действительно хотите удалить?',
  CONFIRM: 'Подтвердить',
  ADD_EVENT: 'Добавить события',
  ADD_STUDY_IN_KOREA: 'Добавить учеба в Корее',
  EDIT_STUDY_IN_KOREA: 'Изменить учеба в Корее',
  FILES: 'Файлы',
  DOCUMENT: 'Документ',
  ADD_DOCUMENT: 'Добавить документ',
  EDIT_DOCUMENT: 'Изменить документ',
  ADD_INSTITUTION: 'Добавить учреждение',
  EDIT_INSTITUTION: 'Изменить учреждение',
  ADD_COLLEGE_LYCEUM: 'Добавить Колледжи / Лицеи',
  EDIT_COLLEGE_LYCEUM: 'Изменить Колледжи / Лицеи',
  ADD_KOREAN_UNIVERSITY: 'Добавить университеты Кореи',
  EDIT_KOREAN_UNIVERSITY: 'Изменить университеты Кореи',
  ADD_UZBEKISTAN_UNIVERSITY: 'Добавить университеты Узбекистан',
  EDIT_UZBEKISTAN_UNIVERSITY: 'Изменить университеты Узбекистан',
  ADD_SCHOOL: 'Добавить школу',
  EDIT_SCHOOL: 'Изменить школу',
  ADD_TOPIK_LEVEL: 'Добавить файлы для учебные материалы',
  ADD_TOPIK_MATERIAL: 'Добавить учебный материаль',
  UPDATE_TOPIK_MATERIAL: 'Изменить Топик материал',
  SELCET_TOPIK_MATERIAL: 'Выбрать Топик материал',
  ADD_STDUY_MATERIAL: 'Добавить учебный материал',
  EDIT_STUDY_MATERIAL: 'Изменить учебный материал',
  EDIT_TOPIK_LEVEL: 'Изменить Топик уровень',
  FILE_TYPE: 'Тип файла',
  SELECT_FILE_TYPE: 'Выбрать тип файла',
  ADD_GKS_PROGRAM: 'Добавить программа GKS',
  ADD_ASSOC_GKS: 'Добавить ассоциация выпускников GKS ',
  ADD_PROGRAM_FOR_KOR: 'Добавить программы для соотечественников',
  EDIT_GKS_PROGRAM: 'Изменить программа GKS',
  EDIT_ASSOC_GKS: 'Изменить ассоциация выпускников GKS ',
  EDIT_PROGRAM_FOR_KOR: 'Изменить программы для соотечественников',
  ADD_ANSWER: 'Добавить ответ',
  EDIT_ANSWER: 'Изменить ответ',
  ADD_INFO: 'Добавить информацию',
  EDIT_INFO: 'Изменить информацию',
  ADD_MUG: 'Добавить кружок',
  EDIT_MUG: 'Изменить кружок',
  ADD_PARTNER: 'Добавить партнер',
  EDIT_PARTNER: 'Изменить партнер',
  WEB_URL: 'Web сайт',
  ADD_GALLERY: 'Добавить галерею',
  EDIT_GALLERY: 'Изменить галерею',
  MAKE_MAIN_PHOTO: 'Сделать главной',
  FILTER: 'Фильтр',
  PASSWORD_SUCCESSFULLY_CHANGED: 'Пароль успешно изменен',
  DESCRIPTION: 'Описание',
  ENTER_DESCRIPTION: 'Введите описание',
}
