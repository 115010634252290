import { Components } from '@mui/material'

export const MuiPaginationItemComponent = (): Components['MuiPaginationItem'] => ({
  styleOverrides: {
    rounded: {
      borderRadius: 'unset',
      margin: 0,
    },
  },
})
