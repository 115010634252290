import { Components } from '@mui/material'
import { PRIMARY_COLOR, TEXT_COLOR } from 'Constants/Colors'

export const MuiOutlinedInputComponent = (): Components['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      borderRadius: 10,
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '16px',

      input: {
        fontSize: 14,
        color: TEXT_COLOR.primary,
        fontWeight: 400,

        '&::placeholder': {
          color: TEXT_COLOR.secondary,
          opacity: 1,
        },
      },

      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(137, 146, 169, 0.2)',
        },
      },

      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: PRIMARY_COLOR.main,
          borderWidth: '1px',
        },
      },

      '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(137, 146, 169, 0.2)',
      },
    },
  },
})
