import { Components } from '@mui/material'

export const MuiPaperComponent = (): Components['MuiPaper'] => ({
  styleOverrides: {
    root: {
      borderRadius: 10,

      '&.box-shadow': {
        boxShadow: '0 14px 24px rgba(20, 23, 38, 0.12)',
      },
    },
  },
})
