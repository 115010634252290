import { MainLayout } from 'Components/Layouts'
import { SuspenseUI } from 'Components/UI'
import { CommonPath, PathParams, TeacherPath, UserPath } from 'Constants/Navigation'
import { TeacherAddPage, TeacherEditPage, TeachersPage } from 'Views/Teacher'
import { UserAddPage, UserEditPage, UsersPage } from 'Views/User'

export const userRoutes = {
  path: '',
  children: [
    {
      path: `/${TeacherPath.main}`,
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: (
            <SuspenseUI>
              <TeachersPage />
            </SuspenseUI>
          ),
        },
        {
          path: `${CommonPath.edit}/:teacherId`,
          element: (
            <SuspenseUI>
              <TeacherEditPage />
            </SuspenseUI>
          ),
        },
        {
          path: CommonPath.add,
          element: (
            <SuspenseUI>
              <TeacherAddPage />
            </SuspenseUI>
          ),
        },
      ],
    },
    {
      path: `/${UserPath.main}`,
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: (
            <SuspenseUI>
              <UsersPage />
            </SuspenseUI>
          ),
        },
        {
          path: CommonPath.add,
          element: (
            <SuspenseUI>
              <UserAddPage />
            </SuspenseUI>
          ),
        },
        {
          path: `${CommonPath.edit}/:${PathParams.userId}`,
          element: (
            <SuspenseUI>
              <UserEditPage />
            </SuspenseUI>
          ),
        },
      ],
    },
  ],
}
