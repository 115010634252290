import { Components } from '@mui/material'

export const MuiDialogComponent = (): Components['MuiDialog'] => ({
  styleOverrides: {
    root: {
      '.MuiPaper-root': {
        boxShadow: '0 4px 64px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#fff',
      },
    },
  },
})
